(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name neo.directive:neoMatch
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="neo">
       <file name="index.html">
        <neo-match></neo-match>
       </file>
     </example>
   *
   */
  angular
    .module('neo')
    .directive('neoMatch', neoMatch);

  function neoMatch($parse) {
    return {
      restrict: 'A',
      require: '^ngModel',
      link: function (scope, element, attrs, ngModel) {
        var firstWordGetter = $parse(attrs.neoMatch);

        function getFirstWord() {
          return firstWordGetter(scope);
        }

        scope.$watch(getFirstWord, function(){
          ngModel.$$parseAndValidate();
        });

        ngModel.$validators.neoMatch = function(modelValue) {
          var firstWord = getFirstWord();
          return (!modelValue && !firstWord) || (modelValue === firstWord);
        };

      }
    };
  }
}());

